const DEV = process.env.NODE_ENV === 'development'
import PhotoSwipeLightbox from 'photoswipe/lightbox'
import 'photoswipe/style.css'

export default {
  init() {
    const content = document.getElementById('mainContent')
    const closeSvgString =
      '<svg width="24" height="24" viewBox="0 0 24 24" fill="#ffffff" xmlns="http://www.w3.org/2000/svg"><path d="M 23.142578 0 C 22.923216 0 22.704509 0.084585725 22.537109 0.25195312 L 14.857422 7.9296875 L 14.857422 3 C 14.857422 2.52661 14.4734 2.1425781 14 2.1425781 C 13.5266 2.1425781 13.142578 2.52661 13.142578 3 L 13.142578 9.8574219 C 13.142578 10.330772 13.5266 10.714844 14 10.714844 L 20.857422 10.714844 C 21.330822 10.714844 21.714844 10.330772 21.714844 9.8574219 C 21.714844 9.3840319 21.330822 9 20.857422 9 L 16.212891 9 L 23.748047 1.4628906 C 24.082847 1.1281606 24.082847 0.58668813 23.748047 0.25195312 C 23.580697 0.084585725 23.361941 0 23.142578 0 z M 3 13.142578 C 2.52661 13.142578 2.1425781 13.5266 2.1425781 14 C 2.1425781 14.4734 2.52661 14.857422 3 14.857422 L 7.9296875 14.857422 L 0.25195312 22.537109 C -0.082781675 22.871809 -0.082781675 23.413247 0.25195312 23.748047 C 0.58668813 24.082747 1.1281606 24.082747 1.4628906 23.748047 L 9 16.212891 L 9 20.857422 C 9 21.330722 9.3840419 21.714844 9.8574219 21.714844 C 10.330802 21.714844 10.714844 21.330722 10.714844 20.857422 L 10.714844 14 C 10.714844 13.5266 10.330802 13.142578 9.8574219 13.142578 L 3 13.142578 z " /></svg>'
    let interval

    const lightbox = new PhotoSwipeLightbox({
      closeSVG: closeSvgString,
      gallery: '#painting',
      pswpModule: () => import('photoswipe'),
      bgOpacity: 1,
      wheelToZoom: true,
      pinchToClose: false,
      closeOnVerticalDrag: false,
    })
    lightbox.on('uiRegister', function () {
      lightbox.pswp.ui.registerElement({
        name: 'timer',
        order: 1,
        isButton: false,
        className: 'body-1-lg text-white h-15s flex items-center pl-6s',
        html: '00:00',
        onInit: (el) => {
          startTimer(el, 10, () => {
            lightbox.pswp.close()
            lightbox.on('closingAnimationEnd', () => {
              content.style.display = 'block'
              content.scrollIntoView()
            })
          })
        },
      })
    })

    lightbox.on('openingAnimationEnd', () => {
      disableScroll()
    })
    lightbox.on('closingAnimationEnd', () => {
      enableScroll()
      clearInterval(interval)
    })

    const disableScroll = () => {
      document.documentElement.classList.add('overflow-hidden')
      document.documentElement.style.paddingRight = '15px'
    }
    const enableScroll = () => {
      document.documentElement.classList.remove('overflow-hidden')
      document.documentElement.style.paddingRight = '0px'
    }

    const startTimer = (element, durationInMinutes, onComplete) => {
      const display = element
      let totalSeconds = durationInMinutes * 60
      let currentSeconds = 0

      interval = setInterval(() => {
        currentSeconds++

        const minutes = Math.floor(currentSeconds / 60)
        const seconds = currentSeconds % 60

        display.innerHTML =
          String(minutes).padStart(2, '0') +
          ':' +
          String(seconds).padStart(2, '0')

        if (currentSeconds >= totalSeconds) {
          clearInterval(interval)
          if (typeof onComplete === 'function') {
            onComplete()
          }
        }
      }, 1000)
    }

    lightbox.init()
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
}
